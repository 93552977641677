.stream-control {
  // display: flex;
  // align-items: flex-end;
  // width: 42px;
  // height: 42px;
  // position: relative;

  &:hover .icons-tip {
    display: block;
  }

  > a > span {
    display: block;
    height: 100%;
    width: 100%;
    background: #fff;
    border-radius: 100px;
    text-align: center;
    background-color: #f82d00;
    cursor: pointer;

    img {
      max-width: 100%;
      width: 20px;
      height: 20px;
      margin-top: 50%;
      transform: translateY(-50%);
      border: none;
    }
  }
}
.stream-control > a {
  width: 100%;
}
.stream-control-container {
  .stream-control-primary_icon_exit {
    justify-self: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 23px;
      height: 45px;
      fill: #dd6e64;
    }
  }
}
