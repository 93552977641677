.preshow-talk:not(.masterclass-container) {
  .streams-container {
    position: relative;
    .streamlabel {
      // position: absolute;
      // left: 50px;
      // top: 15px;
      // z-index: 2;
      text-transform: uppercase;
      color: #fff;
      letter-spacing: 4px;
      font-size: 1.3em;
      display: flex;
      align-items: center;
      span {
        text-shadow: 0 0 3px #999;
      }
      span.logo {
        // background: rgba(0, 0, 0, 0.1);
        padding: 4px;
        border-radius: 2px;
        display: block;
        margin: 0px;
      }
    }
  }
  .no-stream {
    color: #fff;
  }
}

.top-bar-more-streams {
  margin-bottom: 10px !important;
  padding: 0px !important;
  position: unset !important;
}

.stream-section-more-streams {
  height: 83% !important;
}

.individualclass-container .stream-section-more-streams {
  height: 85% !important;
}

._inner.preshow-talk.individualclass-container.full-screen {
  .stream-section.stream-section-more-streams {
    height: 89% !important;
  }
}

.logo {
  margin: 0;
}
._inner.preshow-talk.individualclass-container.full-screen {
  .large-class.overlay-video-container {
    .main-stream-section {
      border-radius: 0 !important;
    }
  }
}
div#root .startnow-top-bar,
div#root .streams-container._left {
  // flex: 0 1 calc(100%) !important;
}
div#root .full-screen .startnow-top-bar,
div#root .full-screen .streams-container._left {
  // flex: 0 0 calc(100%) !important;
}

.masterclass {
  // background-color: #292727;
  // padding: 5px;
  border-radius: 15px;
}
.streamlabel-live-text {
  font-size: 14px;
  color: #16c79a;
  font-weight: bold;
  letter-spacing: 0.7px;
  text-shadow: 0 0 3px #999;
}
.preshow-talk {
  // padding: 1rem;
  box-sizing: border-box;
  .streams-container {
    position: relative;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    .top-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1.5rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      height: 9%;
      width: -webkit-fill-available;
      &-absolute {
        position: absolute;
        z-index: 1023;
        background-color: transparent !important;
        // width: 96%;
      }
      .top-bar-logo {
        img {
          width: 6rem;
        }
      }
      &-time {
        display: flex;
        align-items: center;
      }
      .streamlabel {
        text-transform: uppercase;
        display: flex;
        align-items: center;
        background: #00000099;
        padding: 7px 9px;
        border-radius: 15px;
        .streamlabel-live-text {
          font-size: 14px;
          color: #16c79a;
          font-weight: bold;
          letter-spacing: 0.7px;
          text-shadow: 0 0 3px #999;
        }
        .logo {
          display: block;
          padding: 0;
          margin: 0;
          font-size: 12px;
          img {
            width: 150px;
          }
        }
        .blink {
          margin: 4px;
        }
      }

      .timer {
        text-align: center;
        font-size: 20px;
        color: #cf7d7d;
        // position: relative;
        border-radius: 25px;
        padding: 7px 13px;
      }
    }
    .stream-section {
      .large-class {
        background-color: #f1e9e6 !important;
        height: 100%;
        .main-stream-section {
          // border-radius: 20px;
          overflow: hidden;
        }
        .side-stream-section {
          padding-left: 10px;
          overflow: auto;
          &::-webkit-scrollbar {
            display: none;
          }
          .side-stream {
            height: calc((100% - 10px) / 3.5);
            position: relative;
            width: 100%;
            border-radius: 10px;
            overflow: hidden;
            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

.full-screen {
  .top-bar {
    position: absolute;
    z-index: 1023;
    width: 95%;
    // &-time {
    // display: none !important;
    // }
  }
  .stream-section {
    .side-stream-section {
      display: none;
    }
  }
}
.preshow-talk.individualclass-container {
  padding: 0;
  .streams-container {
    display: block;
    .top-bar {
      margin: 0;
      position: absolute;
      top: 20px;
      z-index: 2;
      width: 100%;
      padding: 0 25px;
      box-sizing: border-box;
    }
    .stream-section {
      // border-radius: 20px;
      .large-class.overlay-video-container {
        display: block;
        .overlay-video {
          position: absolute;
          width: 250px;
          bottom: 13%;
          right: 25px;
          height: 220px;
          border-radius: 10px;
          overflow: hidden;
          .side-stream {
            height: 100%;
          }
        }
      }
    }
  }
}
.full-screen.preshow-talk.individualclass-container
  .streams-container
  .stream-section
  .large-class.overlay-video-container
  .overlay-video {
  width: 350px;
  height: 285px;
}
._inner.preshow-talk.masterclass-container.full-screen {
  // padding: 0px 12px 0px 0px !important;
  background-color: #f1e9e6;
}
.full-screen > section {
  border-radius: 0 !important;
}
div#root .startnow-top-bar.second-stream,
div#root .streams-container._left.second-stream {
  flex: 0 0 calc(100%) !important;
}

.chat-container {
  display: flex;
  flex-direction: column;
}

.startNow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #292727;
}
.startnow-streamlabel {
  display: flex;
  align-items: center;
  background-color: #00000099;
  width: fit-content;
  padding: 7px 9px;
  border-radius: 15px;
  height: 25px;
}
._inner.preshow-talk.individualclass-container {
  flex: 0 0 calc(100%) !important;
  padding: 20px 0px;
}
.startnow-top-bar {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: #292727;
  padding: 20px;
}
.time-left {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #949393;
  font-size: 25px;
  font-weight: 700;
}

.startbutton-container {
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.side-stream-section.showgrid {
  padding-left: 0px !important;
}
.startButton {
  background-image: linear-gradient(#178b84, #16c297);
  color: white;
  border: none;
  padding: 20px 35px;
  font-size: 22px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  //16c297
}

.blinkdot {
  width: 11px;
  height: 11px;
  background: red;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
  margin: 4px;
}

.blink {
  width: 11px;
  height: 11px;
  background: red;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}
.logo > img {
  vertical-align: middle;
  width: 150px;
}
.event-room-page.wrapper {
  height: 100%;
}

@-webkit-keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073,
      0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }

  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6,
      0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}

.blink {
  animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}
@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/* To fix left and right extra line issue */
.agora-video-view > div > div {
  background-color: #dfdfdf !important;
}
//   .remote-stream-video-view + .remote-stream-video-view {
//   	margin-left: 1px;
//   }

#main-streams .stream-section {
  width: 100%;
  max-height: 84%;
  height: 84%;
}
.full-screen {
  #main-streams .stream-section {
    max-height: 100%;
    height: 100%;
  }
}

.sidebar-container {
  // z-index: 9999;
  position: absolute;
  right: 5px;
  top: 11vh;

  .close-chat-mobile {
    position: absolute;
    right: 5px;
    background: white;
    border: none;
    top: 15px;
    svg {
      font-size: 2em;
    }
  }
}

@media only screen and (max-width: 900px) {
  .individualclass-container .stream-section-more-streams {
    height: 82% !important;
  }
  ._inner.preshow-talk.individualclass-container.full-screen {
    .stream-section.stream-section-more-streams {
      height: 85% !important;
    }
  }
  // .top-bar-logo {
  //   // padding: 1rem !important;
  // }
  .preshow-talk .streams-container .top-bar {
    // padding: 0.5rem 0.8rem !important;
  }
  .sidebar-container {
    // top: 1vh !important;
  }
  .top-bar {
    position: absolute;
    z-index: 1023;
    width: -webkit-fill-available;
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  .top-bar:has(.network-loader-position) {
    position: initial;
  }
  #main-streams .stream-section {
    max-height: 100%;
    height: 100%;
  }
  .side-stream-section {
    .streamUserName {
      font-size: 12px;
    }
  }
}

@media screen and (orientation: landscape) and (max-width: 967px) {
  .top-bar-logo {
    img {
      width: 5rem !important;
    }
  }

  .side-stream-section {
    // display: none !important;
    position: absolute !important;
    top: 60px;
    right: 10px;
    overflow: visible !important;
    .streamUserName {
      font-size: 13px;
    }
  }

  // #main-streams .stream-section {
  //   width: 100%;
  //   max-height: 100%;
  //   height: 90%;
  // }
  .streambottomicons {
    height: 9% !important;
    padding: 0;
  }
}

@media screen and (orientation: landscape) and (max-width: 867px) {
  .side-stream {
    height: 100px !important;
  }
}

.Full-screen-hide {
  .stream-section {
    height: 100% !important;
    max-height: 100% !important;
  }

  .large-class .main-stream-section {
    height: 100% !important;
  }

  .hide-side-stream {
    display: none !important;
  }
}
