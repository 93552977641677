.showgrid {
  display: flex;
  flex-direction: row;
  width: 100% !important;
  flex-wrap: wrap;
  justify-content: center;
}
.gridVideo {
  height: 100% !important;
}
.large-class {
  height: 100%;
  display: flex;
  flex-direction: row;
  object-fit: contain;

  .main-stream-section {
    height: inherit;
    flex-grow: 2;

    .main-stream {
      height: inherit;
      position: relative;
      // border-radius: 10px;
    }
    .main-stream.no-video {
      background-color: #dec9c1;
      display: flex;
      justify-content: center;
      align-items: center;

      h3 {
        color: white;
        font-size: 50px;
      }
    }
  }

  .side-stream-section {
    height: inherit;
    width: 25%;
    // overflow-y: scroll;
    .side-stream {
      height: 150px;
      position: relative;
    }
  }
}

.agora-rtc-video {
  width: 100%;
  // div {
  //   background-color: transparent !important;
  // }
}

.agora-rtc-video > div {
  background-color: #e6c3b5 !important;
}
