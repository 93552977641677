.login {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15rem;
    border-radius: 22px;
    position: fixed;
    &-title {
      color: #dd6e64;
      font-weight: bold;
      font-size: 30px;
      margin: 2rem;
      font-family: "Libre Baskerville";
    }
    div {
      img {
        width: 10rem;
      }
    }
  }
  &-text {
    color: #dd6e64;
    font-size: 20px;
    text-align: start;
    margin-bottom: 1rem;
  }
  &-form {
    display: flex;
    flex-direction: column;
    label {
      color: #dd6e64;
      margin: 0.2rem 0rem;
    }
    &-input {
      padding: 0.5rem;
      margin: 0.5rem 0rem;
      border-radius: 5px;
      border: 1px solid grey;
      height: 1.5rem;
      width: 16rem;
      &:hover {
        border: 1px solid black;
      }
    }
    &-button {
      background-color: #dd6e64;
      border: none;
      border-radius: 5px;
      padding: 0.5rem;
      margin: 1rem 0rem;
      color: white;
      cursor: pointer;
      font-weight: bold;
      font-size: 15px;
      &:hover {
        background-color: #f05648;
      }
    }
  }
  &-footer {
    font-size: 15px;
    text-align: center;
    &-term {
      text-align: center;
      padding: 1rem 0;
      font-size: 14px;
      &-link {
        padding: 0.5rem 0;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .login {
    height: 85%;
  }
}
