.privacy-policy {
  display: flex;
  flex-direction: column;
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  &-content {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    width: 90%;
    // color: white;
    // background-color: #dd6e64;
    border-radius: 10px;
    padding: 1rem;
    .policy {
      p {
        font-size: 16px;
      }
      h4 {
        font-size: 20px;
      }
      h6 {
        font-size: 20px;
      }
      //   a {
      //     // padding: 0rem 5px;
      //   }
    }
  }
}
