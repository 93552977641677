.control-options {
  display: flex;
  // flex: 1;
  width: 70%;
  justify-content: space-evenly;
  align-items: center;
}

span + span {
  //margin-left: 10px;
  margin-left: 3%;
}
.icons-tip {
  position: absolute;
  bottom: 100%;
  left: auto;
  right: auto;
  z-index: 1;
  display: none;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
  margin: 0;
}
.audio .icons-tip,
.video .icons-tip,
.exit .icons-tip .icons-tip {
  bottom: 58px;
}
span.exit {
  // background-color: #f82d00;
}
span.exit span {
  background-color: #f82d00;
  width: 100%;
}
.change-device-setting {
  margin-left: 14px;
}
.change-device-setting button,
.select-camera-device button {
  outline: none;
  border-radius: 42px;
  margin: 0;
  border: none;
  width: 100%;
  height: 42px;
  display: inline-block;
  box-sizing: border-box;
  line-height: 100%;
  padding: 10px;
}

// @media only screen and (min-width: 767px) {
//   .host-right-icons {
//       position: absolute;
//       right: 20px;
//       bottom: 10px;
//       z-index: 1;
//   }
// }

.change-device-setting-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  background: wi;
  height: 42px;
  width: 42px;
  background: #fff;
  border-radius: 100px;
  text-align: center;
  &:hover .icons-tip {
    display: block;
  }
}

.moderate-wrapper {
  background: white;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .moderation {
    position: absolute;
    bottom: 50px;
    background-color: white;
    width: 145px;
    height: 90px;
    background: rgba(0, 0, 0, 0.6);
    border: 1px solid white;
  }
}

.preshow-talk {
  .streams-container {
    .streambottomicons {
      .control-options {
        .stream-control {
          height: max-content;
          width: max-content;
          position: relative;
          .stream-control-container {
            display: inline-block !important;
            width: 45px;
            position: relative;
            height: 40px;
            background: #ffffff9e;
            border-radius: 10px;
            .stream-control-primary_icon {
              justify-self: center;
              position: absolute;
              top: 50%;
              left: 43%;
              transform: translate(-50%, -50%);
              display: flex;
              justify-content: center;
              align-items: center;
              svg {
                width: 23px;
                height: 45px;
                fill: #dd6e64;
              }
            }
            .stream-control-secondary_icon {
              margin: 0;
              position: absolute;
              right: -1px;
              top: 50%;
              transform: translateY(-50%);
              svg {
                width: 20px;
                fill: #dd6e64;
              }
            }
            .screen-share-button-mic_icon {
              background: transparent;
              box-shadow: 0px 0px 0px transparent;
              border: 0px solid transparent;
              text-shadow: 0px 0px 0px transparent;
              display: inline-block;
              height: max-content;
            }
          }
          .audio-button-container:hover,
          .video-button-container:hover,
          .screen-share-button-container:hover {
            background: white;
          }
          .end-button-container {
            background-color: rgba(255, 24, 74, 0.575);
            .end-button_icon {
              background-color: transparent;
              svg {
                fill: white;
              }
            }
            &:hover {
              background-color: rgb(255, 24, 74);
            }
          }
        }
      }
    }
  }
}
