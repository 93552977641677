.precall-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  &-logo {
    height: 18%;
    display: flex;
    align-items: center;
    img {
      margin: 1rem;
      width: 10rem;
    }
  }
  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: auto;
    height: 75%;
    .local-stream-preview {
      width: 50%;
      height: 50%;
      transform: rotateY(180deg);
      padding: 0;
      box-sizing: border-box;
      border-radius: 15px;
      div {
        background-color: inherit !important;
        video {
          position: relative !important;
        }
      }
    }

    .local-stream-preview > div {
      border-radius: 15px;
      overflow: hidden;
    }
  }
  &-text {
    margin: 1rem;
  }

  &-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3rem;
  }
  &-option {
    display: flex;
    align-items: center;
    margin: 1rem;
    label {
      width: 2rem;
    }
  }
  &-select {
    width: 15rem;
    padding: 0.5rem;
    border-radius: 10px;
  }
  &-button {
    width: 14rem;
    padding: 0.8rem;
    text-align: center;
    color: white;
    margin: 1rem;
    background-color: #dd6e64;
    border-radius: 10px;
    border: none;
    align-self: end;
    font-size: 18px;
    cursor: pointer;
    &:hover {
      background-color: #b65a52;
    }
  }
}

@media only screen and (max-width: 786px) {
  .precall-screen {
    width: 100%;
    height: -webkit-fill-available;
    &-select {
      width: 14rem;
    }
    &-options {
      margin: 0;
    }
  }
  .precall-screen-content {
    flex-direction: column;
    margin: 1rem;
  }
  .local-stream-preview {
    width: 75% !important;
  }
}
