.main-layout-container {
  background: #EEF1F3;
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
  justify-content: center;

  .layout-content {
    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 20px;
      color: gray;
    }
    
  }
}