/* The Modal (background) */
.modal {
  position: absolute; /* Stay in place */
  z-index: 999; /* Sit on top */
  /* padding-top: 100px;  Location of the box */
  padding-top: 0;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  font-size: 1.2em;
  text-align: center;
  display: flex;
  backdrop-filter: blur(10px);
}

/* Modal Content */
.modal-content {
  margin: auto;
  /* border: 1px solid #888;*/
  border: none;
  width: 95% !important;
  max-width: 500px !important;
  border-radius: 5px;
  position: relative;
  background: #fff;
}
.guide-wrapper > h2,
.s-card-inner > h2 {
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 100%;
  font-family: "Fjalla One", sans-serif;
}
/* The Close Button */
.close {
  color: #aaaaaa;
  font-weight: bold;
  position: absolute;
  top: -15px;
  right: -10px;
  line-height: normal;
  background: #fff;
  width: 30px;
  height: 30px;
  line-height: normal;
  vertical-align: middle;
  border-radius: 50%;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color: #dd6e64 !important;
  cursor: pointer;
}
.close {
  svg {
    color: white;
    cursor: pointer;
  }
  // img {
  //   width: 15px;
  //   cursor: pointer;
  // }
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.modal button {
  padding: 10px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  outline: none;
  background: #fff;
  color: #306f78;
  transition: all 0.4s ease-in-out;
  font-size: 14px;
  // letter-spacing: 0.2px;
  margin: 0.1rem;
}

.m-cards h2 {
  color: #fff;
  text-align: center;
}
.m-card-wrapper {
  display: flex;
  padding: 15px 5px;
  border-radius: 10px;
  box-sizing: border-box;
}
.m-card-inner {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #fff;
  box-sizing: border-box;
  width: 50%;
}

.m-card-img {
  width: 100%;
  height: 170px;
  border-radius: 5px 5px 0 0;
  background-color: #f6f6f6;
  box-sizing: border-box;
}
.m-card-img img {
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.m-card-info {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}
.m-card-info h4 {
  margin-top: 0;
  margin-bottom: 10px;
}
.m-card-info p {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 14px;
}
.m-close-btn {
  position: absolute;
  right: 30px;
  top: 25px;
  line-height: normal;
  cursor: pointer;
}

.s-card-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.s-card-img {
  height: 300px;
  width: 100%;
  border-radius: 5px 5px 0 0;
}
.s-card-inner img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 0.8;
}
.s-card-wrapper {
  border-radius: 5px;
}
.s-card-info textarea {
  width: 100%;
  min-height: 250px;
  box-sizing: border-box;
  margin-bottom: 15px;
  border: 1px solid #c0c0c0;
  border-radius: 3px;
  outline: none;
  padding: 10px;
  font-family: inherit;
  resize: none;
}

.closePopup {
  width: 42% !important;
  min-width: 16rem;
  padding: 0.5rem;
}

.guide-wrapper > h5,
.s-card-inner h5 {
  color: #dd6e64;
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
  width: 90%;
}

.s-btn {
  padding: 0.5rem;
  &-quit {
    padding: 0.8rem !important;
    background-color: #dd6e64 !important;
    color: white !important;
    &:hover {
      background-color: #ff5d4e !important;
    }
  }
  button {
    font-size: 1rem;
    font-weight: 500;
    width: 9.5rem;
  }
}
