.expand {
  height: max-content;
  width: max-content;
  position: relative;

  &-position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      fill: #dd6e64;
    }
  }
  &-icon {
    display: inline-block !important;
    width: 42px;
    position: relative;
    height: 40px;
    background: rgba(255, 255, 255, 0.6196078431);
    border-radius: 10px;
    cursor: pointer;
  }
  &:hover .icons-tip {
    display: block;
  }
}
