#footer-tm.et_pb_row.et_pb_row_0_tb_foote {
  margin-right: 0px !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
#footer-tm {
  text-align: center;
  color: #ffffff !important;
  background-color: black;
}
#footer-tm > div {
  display: inline-block;
  color: #ffffff !important;
  margin-left: 1.5vw;
  margin-right: 1.5vw;
  vertical-align: top;
  margin-top: 50px !important;
  background-color: black;
}
#footer-tm h2 {
  padding: 0 !important;
  margin: 0 !important;
  margin-bottom: 25px !important;
  color: #ffffff !important;
  font: normal normal normal 13px/20px Roboto !important;
  border-bottom: solid 1px rgba(255, 255, 255, 0.3) !important;
  text-transform: uppercase !important;
  text-align: left !important;
  background-color: black;
}

#footer-tm a {
  font: normal normal normal 13px/20px Roboto;
  color: #ffffff;
  display: block;
  margin-bottom: 10px;
  text-align: left;
  display: flex;
  align-items: center;
  background-color: black;
}
