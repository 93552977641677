.guide-listing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  &-table {
    &-height {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 80%;
      overflow-y: auto;
    }
    &-no-response {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 40%;
      &-text {
        color: #dd6e64;
        font-size: 30px;
        text-align: center;
        padding: 2rem;
      }
      &-button {
        padding: 1rem;
        border: none;
        border-radius: 10px;
        color: white;
        background-color: #dd6e64;
      }
    }
  }
}
