.volume-container {
  display: block;
  flex-direction: row;
  overflow: hidden;
}

.voice-sliders {
  height: 24px;
  display: flex;
  align-items: center;
  width: 100%;
  span {
    margin: 1px;
    flex: 1;
    min-height: 12px;
    min-width: 2px;
    background-color: #CCCCCC;
    &.active {
      background-color: #44A2FC;
    }
  }
}

