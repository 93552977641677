.agora-rtc-video {
  height: 100%;
  // &:hover {
  //   .stream-controls {
  //     display: flex;
  //     position: absolute;
  //     top: 50%;
  //     left: 15%;
  //     align-items: center;
  //     width: 70%;
  //     justify-content: space-between;
  //     span {
  //       span {
  //         padding: 0.5rem;
  //         svg {
  //           fill: #dd6e64;
  //         }
  //       }
  //     }
  //   }
  // }
}
.rotateY180deg {
  transform: rotateY(180deg);
}
.stream-details {
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-direction: row-reverse;
  bottom: 10px;
  padding: 5px;
  align-items: flex-end;
}
.stream-icons {
  display: flex;
  height: 25px;
}
.pantool-icon {
  padding-left: 15px;
}

.pin-icon {
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  opacity: 0;
  display: flex;
  justify-content: center;
  img {
    background: rgba($color: #000000, $alpha: 0.35);
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    height: 35px !important;
    z-index: 11;
  }
}

.main-stream:hover,
.side-stream:hover,
.overlay-video:hover {
  .pin-icon {
    opacity: 1;
  }
  .stream-controls {
    display: flex;
    position: absolute;
    top: 1%;
    right: 1%;
    // align-items: center;
    // width: 70%;
    // justify-content: space-between;
    span {
      span {
        padding: 0.3rem;
        svg {
          fill: #dd6e64;
        }
      }
    }
  }
}

.optional-bg {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  > span {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%);
    cursor: pointer;
    height: 60px;
    width: 60px;
    z-index: 2;

    img {
      width: 100%;
    }
  }
}

.remote-stream-controls {
  // width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  // bottom: 2px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 1;
}
.main-stream-section .remote-stream-controls {
  left: 40%;
}
.streamUserName {
  align-items: center;
  position: absolute;
  transform: translate(0%, -50%);
  left: 10px;
  color: #ffffff;
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  font-size: 18px;
  font-weight: bold;
  bottom: -3px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.main-stream-section .streamUserName {
  z-index: 0;
  width: 100px;
  // left: 40px;
  left: 10px;
  font-size: 16px;
}

._inner.preshow-talk.full-screen {
  .streamUserName {
    font-size: 16px;
  }
  .main-stream-section .streamUserName {
    font-size: 20px !important;
  }
}

.remote-stream-controls {
  opacity: 0;
}

.side-stream:hover .remote-stream-controls,
.main-stream-section:hover .remote-stream-controls {
  opacity: 1;
}

.main-stream,
.side-stream {
  background-color: #e6c3b5 !important;
}

@media screen and (max-width: 992px) {
  .main-stream,
  .side-stream {
    background-color: transparent;
  }
}

.no-video-background {
  background-color: #e6c3b5;
}

// .d-none,
// .stream-controls {
//   display: none;
// }
