@mixin important-text {
  color: #dd6e64;
  font-size: 25px;
}
.guide-welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Roboto";
  // justify-content: flex-start;
  height: 95%;
  // &-logo {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   height: 20%;
  //   img {
  //     width: 10rem;
  //   }
  // }
  &-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    // max-height: 70%;
    height: 75%;
    &-name,
    &-header,
    &-time,
    &-residence {
      @include important-text();
    }
    &-tour-place {
      @include important-text();
      padding: 1rem;
      font-weight: bold;
    }
    &-button {
      padding: 1rem;
      border-radius: 10px;
      border: none;
      background-color: #dd6e64;
      color: white;
      width: 15rem;
      cursor: pointer;
      font-size: 20px;
      font-weight: bold;
      &:hover {
        background-color: #e24a3c;
      }
    }
  }
}
