.preshow-talk:not(.masterclass-container) .streams-container {
  background-color: #292727;
  border-radius: 15px;
  .streambottomicons {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    flex-wrap: wrap;
    ._left {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex: 0 0 calc(100% - 320px) !important;
      > div {
        margin-right: 20px;
        position: relative;
      }
      .intract-icon img {
        width: auto;
      }
      .gift-icon-options {
        background: rgba(0, 0, 0, 0.5);
        padding: 15px 10px;
        position: absolute;
        bottom: 40px;
        left: 0;
        min-width: 406px;

        span {
          display: inline-block;
          vertical-align: middle;
          padding-left: 10px;
          padding-right: 10px;
          text-align: center;
          margin: 0;

          img {
            width: auto;
            max-width: 45px;
          }
        }
        label {
          color: #fff;
          font-size: 10px;
          display: block;
          margin-bottom: 2px;

          + p {
            margin-top: 0;
            margin-bottom: 8px;
            font-size: 10px;
            color: #fff;
          }
        }
      }
      .clap-list-options {
        background: rgba(0, 0, 0, 0.5);
        padding: 15px 13px;
        position: absolute;
        bottom: 40px;
        left: 0;
        width: 200px;
        ._list {
          display: flex;
          justify-content: space-between;
          font-size: 10px;
          align-items: center;

          label {
            color: #fff;
            font-size: 11px;
          }
          span {
            padding: 7px 30px 5px 30px;
            box-sizing: border-box;
            border-radius: 50px;
            background: #fff;
            color: #000;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 10px;
            cursor: pointer;
          }
        }
      }
    }
    ._middle {
      display: flex;
      align-items: center;
    }
    ._right {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      > div {
        // margin-left: 20px;
        position: relative;
      }
      .eye-icon span {
        display: block;
        color: #fff;
        font-size: 12px;
        text-align: center;
        letter-spacing: 1px;
        height: auto;
      }
      .eye-icon img {
        cursor: auto;
      }
      input[type="text"] {
        background: transparent;
        border-radius: 50px;
        border: 1px solid #ddd;
        padding: 9px 35px 9px 10px;
        outline: none;
        width: 170px;
        color: #fff;

        &::placeholder {
          color: #fff;
        }
      }
      .heart-icon {
        margin-left: 10px;
        margin-right: 5px;
        position: static;
        > img {
          width: 22px;
          z-index: 2;
        }
        .icons-tip {
          bottom: 52px;
        }
      }
      .comment-box {
        position: relative;
        img {
          width: auto;
          position: absolute;
          z-index: 1;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .chat-icon-content {
        color: #fff;
        position: absolute;
        bottom: 65px;
        left: -20px;
        width: 150px;
        max-height: 250px;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        overflow-y: auto;
        // &::-webkit-scrollbar {
        //   display: none;
        // }
        img {
          width: 30px;
          height: 30px;
          background: #88b6df;
          border-radius: 50px;
          text-align: center;
          overflow: hidden;
          margin-right: 10px;
          min-width: 30px;
          padding: 5px;
          box-sizing: border-box;
        }
        ._info {
          label {
            font-size: 12px;
            display: block;
          }
          p {
            margin-top: 2px;
            margin-bottom: 0;
            font-size: 10px;
            line-height: normal;
          }
        }
        .users-comment {
          display: flex;
          align-items: center;

          + .users-comment {
            margin-top: 10px;
          }
        }
      }
    }
    img {
      cursor: pointer;
      width: 29px;
      vertical-align: middle;
    }
  }

  .streambottomicons ._left .clap-list-options ._list + ._list {
    margin-top: 10px;
  }
  .speaker-icon img {
    max-width: 75px;
    width: auto;
  }
  // .speaker-icon img + img,
  // .gift-icon-options,
  // .clap-list-options,
  // .chat-icon-content {
  //   display: none;
  // }

  .videodisableicon {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    margin-left: auto;
    margin-right: auto;
    max-width: 20%;
    opacity: 0.15;
    font-size: 100px;
  }
}

.preshow-talk.masterclass-container .streams-container {
  .audience {
    flex: 8;
    width: 100%;
  }
  .streambottomicons {
    display: flex;
    // justify-content: space-between;
    padding: 10px;
    background-color: wheat;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    height: 5%;
    // flex: 4;
    .control-options {
      // flex: 3;
    }
    .audience-control {
      flex: 8 1;
    }
    .host-right-icons {
      width: 30%;
      // flex: 1;
      justify-content: space-evenly;
    }
    .participants-control {
      .participants-list-section {
        position: absolute;
        bottom: 0px;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 9;
      }
      .participants-count {
        background-color: #00000043;
        padding: 5px 12px 5px 12px;
        border-radius: 13px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .participants-count-icon {
          svg {
            fill: white;
            width: 30px;
            height: 30px;
          }
        }
        .participants-count-text {
          padding: 0;
          margin: 0;
          color: white;
          margin-left: 6px;
          margin-bottom: 3px;
          font-size: 20px;
        }
      }
    }
    ._left {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      > div {
        margin-right: 20px;
        position: relative;
      }
      .intract-icon img {
        width: auto;
      }
      .gift-icon-options {
        background: rgba(0, 0, 0, 0.5);
        padding: 15px 10px;
        position: absolute;
        bottom: 40px;
        left: 0;
        min-width: 406px;

        span {
          display: inline-block;
          vertical-align: middle;
          padding-left: 10px;
          padding-right: 10px;
          text-align: center;
          margin: 0;

          img {
            width: auto;
            max-width: 45px;
          }
        }
        label {
          color: #fff;
          font-size: 10px;
          display: block;
          margin-bottom: 2px;

          + p {
            margin-top: 0;
            margin-bottom: 8px;
            font-size: 10px;
            color: #fff;
          }
        }
      }
      .clap-list-options {
        background: rgba(0, 0, 0, 0.5);
        padding: 15px 13px;
        position: absolute;
        bottom: 40px;
        left: 0;
        width: 200px;
        ._list {
          display: flex;
          justify-content: space-between;
          font-size: 10px;
          align-items: center;

          label {
            color: #fff;
            font-size: 11px;
          }
          span {
            padding: 7px 30px 5px 30px;
            box-sizing: border-box;
            border-radius: 50px;
            background: #fff;
            color: #000;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 10px;
            cursor: pointer;
          }
        }
      }
    }
    ._middle {
      display: flex;
      align-items: center;
    }
    ._right {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      > div {
        // margin-left: 20px;
        position: relative;
      }
      .eye-icon span {
        display: block;
        color: #fff;
        font-size: 12px;
        text-align: center;
        letter-spacing: 1px;
        height: auto;
      }
      .eye-icon img {
        cursor: auto;
      }
      input[type="text"] {
        background: transparent;
        border-radius: 50px;
        border: 1px solid #ddd;
        padding: 9px 35px 9px 10px;
        outline: none;
        width: 170px;
        color: #fff;

        &::placeholder {
          color: #fff;
        }
      }
      .heart-icon {
        margin-left: 10px;
        margin-right: 5px;
        position: static;
        > img {
          width: 22px;
          z-index: 2;
        }
        .icons-tip {
          bottom: 52px;
        }
      }
      .comment-box {
        position: relative;
        img {
          width: auto;
          position: absolute;
          z-index: 1;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .chat-icon-content {
        color: #fff;
        position: absolute;
        bottom: 65px;
        left: -20px;
        width: 150px;
        max-height: 250px;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        overflow-y: auto;
        // &::-webkit-scrollbar {
        //   display: none;
        // }
        img {
          width: 30px;
          height: 30px;
          background: #88b6df;
          border-radius: 50px;
          text-align: center;
          overflow: hidden;
          margin-right: 10px;
          min-width: 30px;
          padding: 5px;
          box-sizing: border-box;
        }
        ._info {
          label {
            font-size: 12px;
            display: block;
          }
          p {
            margin-top: 2px;
            margin-bottom: 0;
            font-size: 10px;
            line-height: normal;
          }
        }
        .users-comment {
          display: flex;
          align-items: center;

          + .users-comment {
            margin-top: 10px;
          }
        }
      }
    }
    img {
      cursor: pointer;
      width: 29px;
      vertical-align: middle;
    }
  }

  .streambottomicons ._left .clap-list-options ._list + ._list {
    margin-top: 10px;
  }
  .speaker-icon img {
    max-width: 75px;
    width: auto;
  }
  // .speaker-icon img + img,
  // .gift-icon-options,
  // .clap-list-options,
  // .chat-icon-content {
  //   display: none;
  // }

  .videodisableicon {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    margin-left: auto;
    margin-right: auto;
    max-width: 20%;
    opacity: 0.15;
    font-size: 100px;
  }
}

@media only screen and (max-width: 787px) {
  .host-right-icons {
    justify-content: space-around !important;
  }
}
