.chat-icon {
  img {
    &:hover + p {
      display: block;
    }
  }
}
.chat-input-section {
  flex: 1 1;
  position: relative;
  height: 100%;
}
.chat-icon-content {
  height: calc(75vh - 100px);
  // height: 80%;
  /*position: absolute;*/
  min-width: 280px;
  scroll-behavior: smooth;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;

  .users-comment {
    // text-align: right;

    ._info {
      margin-top: 6px;
      margin-bottom: 4px;
      padding: 15px;
      margin: 7px;
      word-break: break-word;
      max-width: 160px;
    }
    label {
      font-size: 16px;
      font-weight: bold;
      display: block;
    }
    p {
      padding: 10px 0px;
      box-sizing: border-box;
      border-radius: 10px;
      font-size: 15px;
      margin-top: 0px;
      margin-bottom: 0;
      display: inline-block;
      line-height: 18px;
      /*word-break: break-all;*/
      font-family: "Montserrat", sans-serif;
      a {
        color: #fff;
      }
    }
    img {
      border-radius: 50%;
      height: 30px;
      width: 30px;
    }
  }
}

// .sidebar-container._right.float-chat-right {
//   position: fixed;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   .chat-container {
//     border-radius: 0;
//   }
// }

.chat-container {
  padding-bottom: 10px;
  // box-sizing: border-box;
  // border-left: 1px solid #ddd;
  max-width: 20rem;
  float: right;
  margin-left: 25px;
  background-color: white;
  border-radius: 20px;
  min-width: 15rem;
  box-shadow: 0px 3px 6px #00000087;
  height: 80vh;
}
.comment-box {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  margin: 5px 0px;
  box-sizing: border-box;
  > input {
    border: 1px solid #aaa;
    border-radius: 50px;
    box-shadow: none;
    padding: 10px;
    width: 80%;
    box-sizing: border-box;
    outline: none;
    padding-right: 40px;
  }
  > img {
    position: absolute;
    right: 32%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
    border-radius: 50%;
    height: 25px;
    width: 25px;
  }
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: grey #fff;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 20px;
  border: 3px solid #fff;
}

.users-comment.sent {
  text-align: right;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  img {
    margin-left: 10px;
  }

  ._info {
    color: #dd6e64;
    border-radius: 10px;
    // text-align: left;
    flex: 0 0 70%;
    max-width: 70%;
    p {
      span + span {
        margin-left: 3px;
      }
    }
  }
}

.users-comment.recieved {
  text-align: left;
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
  ._info {
    // background-color: #f2f2f2;
    color: #dd6e64;
    border-radius: 10px;
    text-align: left;
    flex: 0 0 70%;
    max-width: 70%;

    p {
      span + span {
        margin-left: 3px;
      }

      a {
        color: blue !important;
      }
    }
  }
}

.comment-box .emoji-mart .emoji-mart-emoji:hover::before {
  background: transparent;
}

// .sidebar-container._right.float-chat-right {
//   position: fixed;
//   right: 0;
//   top: 0;
//   bottom: 0;
// }

@media only screen and (orientation: landscape) and (max-width: 867px) {
  .chat-icon-content {
    height: calc(75vh - 120px);
  }
}

@media only screen and (orientation: portrait) and (max-width: 867px) {
  .chat-icon-content {
    height: calc(70vh - 130px);
  }
}
