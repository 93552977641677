.chat-menu {
  display: flex;
  justify-content: flex-start;
  // border-bottom: 2px solid #eee;
  font-size: 16px;
  line-height: 16px;
  color: #444;
  min-height: 80px;
  flex: 1 1;
  font-weight: bold;
  max-height: 80px;
}

.chat-menu .item {
  position: relative;
  padding: 40px 0px 40px 30px;
  cursor: pointer;
  min-width: 85px;
  text-align: center;
  // font-size: 25px;
  color: #cad4dd;
  font: normal normal normal 30px Fjalla One;
  line-height: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  height: 20px;
  width: 20px;
}

.chat-menu-active {
  color: #dd6e64 !important;
}

.full-screen .close-icon {
  display: block;
  font-family: cursive;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.full-screen .float-chat-right .chat-icon-content {
  height: calc(75vh - 100px) !important;
}

// .full-screen .sidebar-container._right.float-chat-right .chat-container {
//  min-height: 99vh;
// }
.full-screen .sidebar-container._right.float-chat-right {
  z-index: 10;
  bottom: 0;
  height: 75vh;
}
