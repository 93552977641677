.stream-control {
  // display: flex;
  // align-items: center;
  // width: 50px;
  // height: 50px;
  // position: relative;

  &:hover .icons-tip {
    display: block;
  }

  > span {
    display: block;
    height: 100%;
    width: 100%;
    background: #fff;
    border-radius: 100px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;

    img {
      max-width: 100%;
      width: 25px;
      height: 25px;
      margin-top: 50%;
      transform: translateY(-50%);
      border: none;
    }
  }
}

.audio.stream-control.controller {
  > span {
    height: 1.5rem;
    width: 1.5rem;
  }
}
