.waiting-screen {
  height: 100%;
  &-content {
    display: flex;
    flex-direction: column;
    height: 85%;
    justify-content: space-around;
  }
}

.welcomeloader {
  &-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    color: #ecb19c;
    text-align: center;
  }
  &-text {
    font-size: 25px;
    text-align: center;
  }
}
