.header-guide {
  display: flex;
  align-items: center;
  height: 18%;
  width: 100%;
  justify-content: space-around; // justify-content: center;
  &-logo {
    img {
      margin: 1rem;
      width: 10rem;
    }
  }
  &-logout {
    padding: 1rem;
    border: none;
    border-radius: 10px;
    color: white;
    background-color: #dd6e64;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
  }
}
