.video-setting-popup {
  position: absolute;
  bottom: 50px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  min-width: 270px;
  max-width: 300px;
  border-bottom: 1px solid lightgray;
  left: 0;
  z-index: 11;
  .video-setting-container {
    padding: 0 20px 10px 20px;
    border-bottom: 1px solid lightgray;
    .video-setting-heading {
      h1 {
        margin: 15px 0;
        color: #dd6e64;
        font-size: 16px;
      }
    }
    .local-stream-preview {
      margin-top: 10px;
    }
    .video-setting-select-box {
      padding: 5px;
      box-shadow: 0px 3px 6px #0000004d;
      border: none;
      color: #00000080;
      box-sizing: border-box;
      width: 100%;
      background: #fff;
      :focus,
      :active,
      :focus-visible {
        border: none;
        outline: none;
      }
    }
    .local-stream-preview {
      height: 150px;
      border-radius: 20px;
      overflow: hidden;
      box-sizing: border-box;
    }
  }

  .video-setting-footer {
    display: flex;
    justify-content: flex-end;
    padding: 15px 20px;
    color: #2d6d76;
    .video-setting-footer__apply {
      margin-left: 15px;
      cursor: pointer;
    }
    .video-setting-footer__cancel {
      cursor: pointer;
    }
  }
}

@media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 900px) {
  .video-setting-popup {
    min-width: 200px;
    max-width: 300px;
  }
  .video-setting-heading {
    h1 {
      margin: 5px !important;
      color: #dd6e64;
      font-size: 16px;
    }
  }
  .local-stream-preview {
    height: 100px !important;
  }
  .video-setting-footer {
    display: flex;
    justify-content: flex-end;
    padding: 10px 15px !important ;
    color: #2d6d76;
    .video-setting-footer__apply {
      margin-left: 15px;
      cursor: pointer;
    }
    .video-setting-footer__cancel {
      cursor: pointer;
    }
  }
}
