.guide-listing {
  &-table {
    border-collapse: collapse;
    margin: 1rem 0;
    width: 90%;
    &-height {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 80%;
      overflow-y: auto;
    }
    &-header {
      tr {
        th {
          padding: 1rem;
          text-align: center;
        }
      }
    }
    &-body {
      tr {
        td {
          padding: 1rem 2px;
          text-align: center;
          .guide-listing-button {
            background-color: #dd6e64;
            border: none;
            border-radius: 5px;
            color: white;
            padding: 0.5rem;
            margin: 0.5rem;
            // width: 4rem;
            cursor: pointer;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }
    &-no-response {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 40%;
      &-text {
        color: #dd6e64;
        font-size: 30px;
        text-align: center;
        padding: 2rem;
      }
      &-button {
        padding: 1rem;
        border: none;
        border-radius: 10px;
        color: white;
        background-color: #dd6e64;
      }
    }
  }

  tbody {
    border: 1px solid #dddddd;
  }
  td,
  th {
    border: 1px solid #dddddd;
  }

  .pagination {
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: flex-end;
  }

  .pagination button {
    color: rgb(255, 255, 255);
    padding: 10px 20px;
    text-decoration: none;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin: 0 0.5rem;
    font-size: 13px;
    background-color: #dd6e64;
    font-weight: bold;
  }
  .pagination button:hover:not(.active) {
    background-color: #ddd;
    border-radius: 5px;
  }
}
