.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
}

.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.error-logo {
  text-align: center;
  img {
    width: 50%;
  }
}
