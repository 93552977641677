.audio-setting-popup {
  position: absolute;
  bottom: 70px;
  background-color: white;
  border-radius: 10px;
  max-width: 300px;
  left: -50px;
  .audio-setting-heading {
    padding: 20px 20px 0px 20px;
    h1 {
      margin: 0;
      color: #00000080;
      font-size: 16px;
    }
  }
  .volume {
    display: flex;
    align-items: center;
  }
  .audio-setting-middleContainer {
    padding: 5px 20px;
    .audio-setting-select-box {
      padding: 5px;
      margin: 10px 0;
      box-shadow: 0px 3px 6px #0000004d;
      border: none;
      color: #00000080;
      width: 100%;
      background: #fff;
      :focus,
      :active,
      :focus-visible {
        border: none;
        outline: none;
      }
    }
    .local-stream-preview {
      height: 150px;
      border-radius: 20px;
      overflow: hidden;
      margin-bottom: 10px;
    }
  }
  .audio-setting-footer {
    display: flex;
    justify-content: flex-end;
    padding: 15px 20px;
    color: #2d6d76;
    .audio-setting-footer__apply {
      margin-left: 15px;
      cursor: pointer;
      font-size: 15px;
      letter-spacing: 0.7px;
    }
    .audio-setting-footer__cancel {
      cursor: pointer;
      font-size: 15px;
      letter-spacing: 0.7px;
    }
  }
}
.video-setting-footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px 20px;
  color: #2d6d76;
  .video-setting-footer__apply {
    margin-left: 15px;
    cursor: pointer;
  }
  .video-setting-footer__cancel {
    cursor: pointer;
  }
}
