.comment-section {
  margin-top: 10px;
  margin-left: 10px;
}
.emoji-mart-bar {
  display: none;
}
.comment-box {
  display: flex;
  margin: 10px;
  position: relative;
  justify-content: space-around;
}
.comment-sent {
  display: flex;
}
.comment-section-button {
  border-radius: 15px;
  padding: 0.5rem;
  border: none;
  font-size: 15px;
  font-weight: bold;
  background-color: #dd6e64;
  color: white;
}
