.center-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: space-evenly;
  align-items: center;
  // width: 100%;
  // height: 90vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
body {
  background-color: #f1e9e6;
}
.loader-ball-12 {
  position: relative;
  top: 0;
  left: 0;
}
.loader-ball-12 > div {
  height: 25px;
  width: 1px;
  position: absolute;
  animation: loader-ball-12-rotate 3.5s linear infinite;
}
.loader-ball-12 > div:after {
  content: "";
  display: inline-block;
  top: 40px;
  height: 12px;
  width: 12px;
  background: #dd6e64;
  border-radius: 50%;
  position: relative;
}
.loader-ball-12 > div:nth-child(1) {
  animation-delay: 0.15s;
}
.loader-ball-12 > div:nth-child(2) {
  animation-delay: 0.3s;
}
.loader-ball-12 > div:nth-child(3) {
  animation-delay: 0.45s;
}
.loader-ball-12 > div:nth-child(4) {
  animation-delay: 0.6s;
}
.loader-ball-12 > div:nth-child(5) {
  animation-delay: 0.75s;
}
.loader-ball-12 > div:nth-child(6) {
  animation-delay: 0.9s;
}
@keyframes loader-ball-12-rotate {
  30% {
    transform: rotate(220deg);
  }
  40% {
    transform: rotate(450deg);
    opacity: 1;
  }
  75% {
    transform: rotate(720deg);
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: rotate(0deg);
  }
}
