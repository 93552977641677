@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap");
html {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif !important;
  background-color: #f1e9e6;
  display: flex;
  flex-direction: column;
  margin: auto;
  height: -webkit-fill-available;
  iframe {
    display: none;
  }
}
button,
input {
  font-family: "Roboto", sans-serif !important;
}
#root {
  height: 100vh;
}

.streams-container {
  position: relative;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;

  .no-stream {
    color: #fff;
  }
}

.notice-message-container {
  top: 0 !important;
}

.streambottomicons .stream-control > span {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@media only screen and (max-width: 767.98px) {
  .wrapper ._inner > ._left {
    height: 100%;
    display: flex;
  }
  .streambottomicons {
    flex-direction: column !important;
    justify-content: start !important;
    padding: 10px 0px !important;
    flex-wrap: wrap !important;
  }
  .control-options {
    display: flex;
    flex: 1 1;
    // justify-content: flex-start !important;
    align-items: center !important;
    flex-wrap: wrap !important;
  }
  .chat-container {
    max-width: 100% !important;
    width: 100%;
    height: 73vh !important;
  }
  .streamlabel span.logo {
    margin-left: 0 !important;
  }
  .comment-box {
    position: absolute !important;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    display: flex;
    bottom: 0;
    min-width: 300px;
    width: 100%;
    bottom: 18px;
  }
  .comment-box > input {
    border: 1px solid #aaa;
    border-radius: 50px;
    box-shadow: none;
    width: 70%;
    box-sizing: border-box;
    outline: none;
    display: flex;
    padding: 10px 40px 10px 10px;
  }
  .wrapper ._inner > ._right {
    // top: 0;
    // left: 0;
    // position: absolute !important;
    background-color: #ffffff36 !important;
    width: 100% !important;
  }
  .wrapper ._inner > ._right .chat-input-section {
    // flex: 1 1;
    position: relative;
    background: transparent !important;
    max-height: 74vh !important;
  }
  .copy-button-section {
    margin-left: 0.1rem !important;
    margin-right: 0.1rem !important;
    min-width: 42px !important;
  }
  .stream-section .large-class {
    flex-wrap: wrap !important;
  }
  // .large-class .side-stream-section {
  //   overflow-y: hidden !important;
  //   overflow-x: auto !important;
  //   height: 4rem !important;
  //   width: 100% !important;
  //   overflow-x: auto !important;
  //   position: absolute !important;
  //   top: 50% !important;
  //   display: flex !important;
  // }
  .large-class .main-stream-section .main-stream.no-video h3 {
    font-size: 2rem !important;
    padding: 1rem !important;
  }
  .large-class .main-stream-section {
    height: 75% !important;
  }
  .large-class .main-stream-section .main-stream {
    height: 50vh !important;
  }
  .chat-container {
    background-color: #fff !important;
    // height: 100vh !important;
  }
  .large-class .side-stream-section .side-stream {
    height: inherit !important;
    min-width: 20% !important;
    max-width: 20% !important;
  }
  .tooltiptext-wrapper {
    width: 100% !important;
  }
  .preshow-talk .streams-container .streamlabel span {
    font-size: 0.7rem !important;
  }
  section.emoji-mart.emoji-mart-light {
    width: 100% !important;
  }
  .logo > img {
    width: 120px !important;
  }
  .network-signal-container {
    left: 2% !important;
  }
  .network-signal-container img {
    width: 1rem !important;
    height: 1rem !important;
  }
  .preshow-talk .streams-container .streamlabel {
    left: 10% !important;
  }

  .copy-button-section button {
    outline: none;
    border-radius: 42px;
    margin: 0;
    border: none;
    width: 42px !important;
    height: 42px !important;
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;
    box-sizing: border-box;
    line-height: 100%;
    padding: 10px 10px;
    background: whitesmoke;
  }
}

@media (max-width: 767px) {
  .large-class > div.main-stream-section,
  .large-class > div.side-stream-section {
    flex: 0 0 100%;
  }
  // .large-class {
  //   .side-stream-none {
  //     display: none !important;
  //   }
  // }
  .large-class .side-stream-section {
    position: static !important;
    height: 23% !important;
  }
  .large-class .main-stream-section {
    height: 75% !important;
  }
  .large-class .main-stream-section .main-stream,
  .large-class .side-stream-section .side-stream {
    height: 100% !important;
  }
  .large-class .side-stream-section .side-stream {
    min-width: 50% !important;
    max-width: 50% !important;
  }
  div.streambottomicons {
    // overflow-x: auto;
    // overflow-y: hidden;
    flex-wrap: nowrap !important;
    flex-direction: row !important;
  }
  div.streambottomicons .control-options {
    flex-wrap: nowrap !important;
  }
  span.exit > span {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: inline-block;
  }
  div.control-options > span {
    margin: 0 5px;
  }
  div.stream-control > span > img {
    width: 20px;
    height: auto;
  }
  div.streambottomicons ._right > div {
    margin: 0;
  }
  .chat-control button.stream-control.chat-control-icon {
    // background: #000;
    border-radius: 100%;
    // margin: 0 5px;
  }
  button.stream-control.chat-control-icon > img {
    height: 20px;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }
  div.streambottomicons ._right {
    flex-wrap: nowrap;
  }
  span.moderate-wrapper .moderation {
    position: fixed;
    bottom: 80px;
  }
  span.stream-control > span img {
    width: auto;
    height: 20px;
    object-fit: contain;
  }
  // section.chat-container {
  // background: transparent !important;
  // height: 100% !important;
  // justify-content: flex-end;
  // }
  // div.wrapper ._inner > ._right {
  // height: 68vh;
  // }
  // .wrapper ._inner > ._right .chat-input-section {
  // max-height: 200px !important;
  // overflow-x: hidden;
  // overflow-y: scroll;
  // margin-bottom: 20px;
  // }
  // div.comment-box {
  // position: relative !important;
  // }
  // .sidebar-container._right .chat-menu,
  // section.sidebar-container .close-chat-mobile {
  //   display: none;
  // }
  div.timer {
    left: 25%;
    top: 50px;
  }
  div.streambottomicons {
    background: #333;
  }
  div.streambottomicons + .streambottomicons {
    display: none;
  }
}

@media only screen and (orientation: landscape) and (max-width: 867px) {
  .large-class .main-stream-section {
    height: 100% !important;
  }
  .large-class .side-stream-section .side-stream {
    min-width: 100% !important;
  }
  .large-class .side-stream-section {
    position: absolute !important;
    height: 30% !important;
  }
  .hide{
    display: none;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .large-class .side-stream-section {
    overflow-y: hidden !important;
    overflow-x: auto !important;
    width: 100% !important;
    overflow-x: auto !important;
    display: flex !important;
    padding: 0 !important;
    .side-stream {
      margin-left: 4px;
    }
  }
}
