.wrapper {
  //background: #121212;
  //font-family: 'Poppins', sans-serif;
  height: calc(100% - 37px);
  // max-width: 1050px;
  //padding: 25px 5%;
  box-sizing: border-box;

  ._inner {
    display: flex;
    height: 100%;

    > ._left {
      // flex: 3.6;
      width: 100%;
      // flex: 1;
      ._top {
        min-height: 460px;
        margin-bottom: 5px;
      }
    }
    > ._right {
      // flex: 1;
      position: absolute;
      bottom: 65px;
      right: 5px;
      //margin-left: 1.8%;
    }
  }

  .topbar + .main-sec {
    margin-top: 25px;
  }
}

.main_container_video_section {
  display: grid;
  grid-template-columns: repeat(36, 1fr);
  height: 80vh;
  grid-template-rows: repeat(36, 1fr);
}

.wrapper ._inner > ._left ._top {
  display: grid;
  grid-column: 1/37;
  grid-row: 1/37;
  grid-template-rows: repeat(36, 1fr);
  height: 100%;
  background: #fff;
}

.screen-share-section-div {
  grid-column: 1/30;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row: 1/37;
}

.agora-rtc-screen-shared {
  background: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  // max-width: 300px;
  // max-height: 300px;
  // border-radius: 10px;
  color: #fff;
  width: 100%;
  height: 100%;
  text-align: center;
}

.wrapper
  ._inner
  > ._left
  ._top.agora-local-video-view-top
  .agora-local-video-view-only {
  grid-row: 1/37;
  grid-column: 1/37;
}
// .wrapper ._inner > ._left ._top > div:first-child {
//   grid-row: 1/8;
// }
// .wrapper ._inner > ._left ._top > div:nth-child(2) {
//   grid-row: 9/15;
// }

.wrapper ._inner > ._left ._top.agora-local-video-view-top {
  grid-column: 1/37;
  grid-row: 1/37;
}

.wrapper ._inner > ._left ._top > div.remote-stream-video-view {
  grid-row: 1/37;
  grid-column: 1/29;
}

// div.wrapper ._inner > ._left ._top > div.agora-local-video-view {
//   grid-row: 1/7;
//   grid-column: 30/37;
// }

.wrapper
  ._inner
  > ._left
  ._top.agora-local-video-view-top
  > div.remote-stream-video-view {
  grid-column: 1/29;
}

div.wrapper
  ._inner
  > ._left
  ._top
  > .agora-video-view.agora-local-video-view-only {
  grid-column: 1/37;
}

.screen-share-section-div ~ ._top {
  grid-column: 30/37 !important;
}

.screen-share-section-div
  ~ ._top
  > .agora-video-view.agora-local-video-view-only {
  grid-row: 1/7;
}

.screen-share-section-div .agora-rtc-video > div > video {
  width: calc(100% + 2px) !important;
  height: calc(100% + 2px) !important;
  object-fit: contain !important;
  top: -1px !important;
  left: -1px !important;
  position: relative !important;
}

.wrapper ._inner > ._left ._top > div:first-child {
  grid-row: 1/8;
  grid-column: 1/37;
}
.wrapper ._inner > ._left ._top > div:nth-child(2) {
  grid-row: 8/15;
  grid-column: 1/37;
}

.wrapper ._inner > ._left ._top > div:nth-child(3) {
  grid-row: 15/22;
  grid-column: 1/37;
}

.wrapper ._inner > ._left ._top > div:nth-child(4) {
  grid-row: 22/29;
  grid-column: 1/37;
}

// mutliple stream css
.wrapper ._inner > ._left ._top._multipleStream > div:first-child {
  grid-row: 1/37;
  grid-column: 1/30;
}

.wrapper ._inner > ._left ._top._multipleStream > div:nth-child(2) {
  grid-row: 1/8;
  grid-column: 30/37;
}

.wrapper ._inner > ._left ._top._multipleStream > div:nth-child(3) {
  grid-row: 8/15;
  grid-column: 30/37;
}

.wrapper ._inner > ._left ._top._multipleStream > div:nth-child(4) {
  grid-row: 15/22;
  grid-column: 30/37;
}

@media only screen and (max-width: 600px) {
  ._inner {
    display: flex;
    height: 100% !important;
  }
}
