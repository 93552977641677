.progress {
  color: "#44A2FC";
}
.loading-container {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  z-index: 99999;
  border-radius: 15px;

  .bluebg {
    background-image: linear-gradient(90deg, #121212, #121212);
  }
  .intro-banner-vdo-play-btn {
    height: 60px;
    width: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    margin: -30px 0 0 -30px;
    border-radius: 100px;
    z-index: 1;
    background-image: url("http://localhost:3000/favicon.png");
    background-color: #2b2b2b;
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
  }
  .intro-banner-vdo-play-btn i {
    line-height: 56px;
    font-size: 30px;
  }
  .intro-banner-vdo-play-btn .ripple {
    position: absolute;
    width: 160px;
    height: 160px;
    z-index: -1;
    left: 50%;
    top: 50%;
    opacity: 0;
    margin: -80px 0 0 -80px;
    border-radius: 100px;
    -webkit-animation: ripple 1.8s infinite;
    animation: ripple 1.8s infinite;
  }

  @-webkit-keyframes ripple {
    0% {
      opacity: 1;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      opacity: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes ripple {
    0% {
      opacity: 1;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      opacity: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  .intro-banner-vdo-play-btn .ripple:nth-child(2) {
    animation-delay: 0.4s;
    -webkit-animation-delay: 0.4s;
  }
  .intro-banner-vdo-play-btn .ripple:nth-child(3) {
    animation-delay: 0.6s;
    -webkit-animation-delay: 0.6s;
  }
}
