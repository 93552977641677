.permission-ask-wrapper {
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 70px;
}

.permission-ask-wrapper .permission-block {
  padding: 20px;
  width: 20rem;
  border: 2px solid indianred;
  border-radius: 10px;
  text-align: center;
  p {
    text-align: center;
  }

  img {
    width: 100%;
  }

  p.message {
    font-weight: 900;
  }

  .hint {
    font-weight: bold;

    h2 {
      display: block;
      margin: 0;
      font-size: 2.5em;
    }
  }
}

button.access-btn {
  margin-top: 20px;
  width: 70%;
  border: none;
  background: #dd6e64;
  color: white;
  height: 30px;
  border-radius: 10px;
}
