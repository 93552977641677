@font-face {
  font-family: "Tantinotes";
  src: url("../../fonts/Tantinotes.ttf") format("truetype");
}
.endscreen {
  background-color: #f1e9e6;
  display: flex;
  flex-direction: column;
  height: 100%;

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 70%;
  }
  &-header {
    font-size: 40px;
    text-align: center;
    padding: 1rem 0;
    font-family: "Roboto";
  }
  &-text {
    font-size: 70px;
    text-align: center;
    padding: 1rem 0;
    font-family: "Tantinotes";
    color: #dd6e64;
    font-weight: 400;
  }
  &-image {
    width: 95%;
    height: 100%;
    aspect-ratio: 1/1;
  }
  &-scheduling-events {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-listing-table {
    border-collapse: collapse;
    margin: 1rem 0;
    width: 90%;
    &-height {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      max-height: 60%;
      overflow-y: auto;
    }
  }
  &-feedback {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin: 2rem;
    &-para {
      text-align: center;
      color: #dd6e64;
      font-size: 20px;
      font-weight: bold;
      max-width: 80%;
    }
    &-button {
      background-color: #dd6e64;
      border: none;
      border-radius: 5px;
      color: white;
      padding: 0.5rem;
      margin: 0.5rem;
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
    }
  }
  &-listing-table {
    &-no-response {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &-text {
        color: #dd6e64;
        font-size: 30px;
        text-align: center;
        padding: 2rem;
      }
      &-button {
        padding: 1rem;
        border: none;
        border-radius: 10px;
        color: white;
        background-color: #dd6e64;
        font-size: 15px;
        font-weight: bold;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .endscreen {
    &-text {
      font-size: 40px;
      text-align: center;
      font-weight: 500;
    }
    &-header {
      font-size: 18px;
      text-align: center;
    }
  }
}

@media screen and (orientation: landscape) and (max-width: 967px) {
  .endscreen {
    height: 100%;
  }
}
