.network-signal-container {
  // position: absolute;
  // top: 20px;
  // left: 1%;
  // z-index: 1;
  margin: 0.5rem;
}

.network-signal-container img {
  width: 25px;
  height: 25px;
}
