.network-loader {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-around;
  &-text {
    font-size: 30px;
    color: #dd6e64;
  }
}
