.icon-container {
  display: flex;
  justify-content: space-between;
  min-width: 46px;
  align-items: center;
}

.icon {
  cursor: pointer;
  display: flex;
  width: 16px;
  height: 16px;
}

.icon:hover {
  &::after {
    display: block;
    content: " ";
    background-color: rgba($color: #000000, $alpha: 0.07);
    border-radius: 4px;
  }
}

.icon-voice,
.icon-speaker,
.icon-speaker-on,
.icon-speaker-off,
.icons-camera-mute-s,
.icons-camera-unmute-s {
  display: flex;
  width: 24px;
  height: 24px;
}

.icon-speaker-on,
.icon-speaker-off {
  width: 37px;
}

.menu-split {
  min-height: 14px;
  width: 2px;
  background: #dbe2e5;
  border: 1px solid #dbe2e5;
  box-sizing: border-box;
  display: inline-block;
  margin: 0 16px;
}

.icon-btn {
  display: flex;
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.icon-btn:focus-within {
  background-color: rgba($color: #000000, $alpha: 0.12);
  border-radius: 16px;
}

.icon-btn:hover {
  &::after {
    content: " ";
    background-color: rgba($color: #000000, $alpha: 0.07);
    border-radius: 16px;
  }
}

.icon-minimum::after {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  content: " ";
  background-image: url("./assets/icon-min@2x.png");
}

.icon-maximum::after {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  content: " ";
  background-image: url("./assets/icon-max@2x.png");
}

.icon-close::after {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  content: " ";
  background-image: url("./assets/icon-close-gray@2x.png");
}

.media-board.panel {
  pointer-events: none;
  cursor: pointer;
}

.media-board {
  .add::after {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: " ";
    background-image: url("./assets/Rectangle-6-Copy-9@2x.png");
  }

  .hand_tool::after {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    content: " ";
    background-size: 20px;
    background-image: url("./assets/icon-move.png");
  }


  .line::after {
    width: 16px;
    height: 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: " ";
    background-image: url("./assets/Line.svg");
  }

  .eraser::after {
    width: 16px;
    height: 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: " ";
    background-image: url("./assets/eraser.svg");
  }

  .highlight {
    padding: 8px;
  }

  .clear-icon {
    padding: 8px;
  }

}
